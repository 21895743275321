<template>
  <div class="login_page">
    <div class="login_form_container">
      <div v-if="errors.length > 0">
        <errors :errors="errors" class="w-100" />
      </div>
      <div class="mb-4" v-if="logo">
        <div class="img_container">
          <v-img :src="logo" cover></v-img>
        </div>
      </div>
      <v-text-field
        v-model="formData.username"
        label="Username"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="formData.password"
        label="Password"
        :type="passwordVisibility ? 'text' : 'password'"
        outlined
        :append-icon="passwordVisibility ? 'fa-eye-slash' : 'fa-eye'"
        @click:append="passwordVisibility = !passwordVisibility"
      ></v-text-field>
      <div class="text-center">
        <v-btn
          @click="login"
          :loading="loadingState"
          color="primary"
          :disabled="!signupValidation"
          >Log in</v-btn
        >
      </div>
      <div style="margin-top: 15px; text-align: center">
        Forget Password?
        <v-btn
          :loading="isPasswordResetting"
          @click="resetPassword"
          small
          color="success"
          class="mx-4"
        >
          Reset
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getLogoData();
  },
  components: {},
  computed: {
    signupValidation() {
      if (this.formData.username && this.formData.password) {
        return (
          this.formData.username.length > 1 && this.formData.password.length > 1
        );
      }
      return false;
    },
  },
  data() {
    return {
      loadingState: false,
      isPasswordResetting: false,
      passwordVisibility: false,
      logo: null,
      errors: [],
      formData: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    getLogoData() {
      this.$http
        .get(api.LOGO)
        .then((res) => {
          this.logo = res.data;
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    login() {
      this.loadingState = true;
      this.$http
        .post(api.LOGIN, { ...this.formData })
        .then((res) => {
          console.log("Res==>", res.data);
          localStorage.setItem("token", JSON.stringify(res.data.access_token));
          window.location.reload();
        })
        .catch((error) => {
          console.log(" Error=======>", error.response);
          if (error.response.status == 401) {
            alert("Wrong Credential, Re-check your username and password");
          } else {
            alert("Server error");
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    resetPassword() {
      this.isPasswordResetting = true;
      this.$http
        .post(api.RESET_PASSWORD)
        .then((res) => {
          alert(
            "A link has been sent to your mail for resetting your password. Please check your mail inbox and spam folder also"
          );
        })
        .catch((error) => {
          console.log("reset password error===>", error.response.data);
          if (error.response.status == 401) {
            this.errors = error.response.data;
            window.scrollTo(0, 0);
          } else {
            alert("Server Error");
          }

          console.log(error.response.data);
        })
        .finally(() => {
          this.isPasswordResetting = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.login_page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(184, 227, 255, 0.664);
  /* background-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  ); */

  /* background-image: radial-gradient(
    circle,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  ); */
}
.login_form_container {
  max-width: 500px;
  width: 100%;
  /* border: 1px solid rgb(42, 44, 40); */
  border-radius: 10px;
  padding: 50px;
  background-color: whitesmoke;
  /* background-color: #5ffbf1; */
}

.img_container {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  margin: auto;
  overflow: hidden;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
